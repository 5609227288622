import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.easings.min'
import '../../assets/scripts/jquery.multiscroll.min'
import '../../assets/scripts/jquery.mousewheel'
import '../../assets/scripts/jquery.nicescroll.min'

var screenWidth = window.screen.width
var scroH = $(document).scrollTop();
var viewH = $(window).height(); //可见高度

//页面滚动监听
$(document).scroll(function () {
    scroH = $(document).scrollTop();
    var newscrolltop = $('.index-news').scrollTop();

    if (scroH > newscrolltop) { //距离底部高度小于100px
        $('.index-news .animate-circle').addClass('active')
    } else {
        $('.index-news .animate-circle').removeClass('active')
    }

});
$(function () {
    setScroll()
    pcBanner()
});
$(window).resize(function () {
    screenWidth = window.screen.width
    if (screenWidth == 1024 || screenWidth == 1366){
        this.location.reload()
    }
});


function setScroll(){
    console.log(screenWidth)
    if (screenWidth > 1024){
        // niceScroll滚动条
        $('body').niceScroll({
            // mousescrollstep: 20,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#004d2c',
            cursorborder: '1px solid #004d2c',
            cursoropacitymax: 0.6,
            smoothscroll: true
        });
    }
}

//pc端banner
function pcBanner(){
    if (screenWidth > 1024) {
        $('#multiscroll').multiscroll({
            // sectionsColor: ['#1bbc9b', '#de564b', '#f9b755'],
            loopTop: false,
            loopBottom: false,
            navigation: true,
            scrollingSpeed: 800,
            navigationPosition: 'left',
            navigationTooltips: ['China Essence Natural', 'Keppel Biotech', 'Keppel Zhongxin', 'Jingchen Dining', 'Weixiangxuan'],
            easing: 'easeInOutCubic',
            paddingTop: '0px',
            paddingBottom: '0px',

            afterRender: function () {
                //alert('初始化完成') ;
            },
            afterResize: function () {
                //alert('浏览器窗口大小调整了') ;
            },
            onLeave: function () {
                // alert('开始滚动') ;
                let index = $('.ms-left .ms-section.active').index()
                $('.index-banner .number-pagination .left .cont').css('transform', 'translateY(-' + index * 20 + '%)')
                $('.index-banner #multiscroll-nav .hk').css('transform', 'translateY(' + index * 50 + 'px)')
                switch (index) {
                    case 0:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#085b81')
                        break;
                    case 1:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#004d2c')
                        break;
                    case 2:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#521212')
                        break;
                    case 3:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#0c636a')
                        break;
                    case 4:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#503323')
                        break;
                    default:
                        $('.index-banner #multiscroll-nav .hk').css('background-color', '#085b81')
                        break;
                }
            },
            afterLoad: function () {
                // alert('滚动完成') ;
                let islast = $('.ms-left .ms-section').last().hasClass('active')
                if (islast) {
                    $('body').bind('mousewheel', function (event, delta) {
                        if (delta > 0 && islast && scroH == 0) {
                            $.fn.multiscroll.setMouseWheelScrollingAdd()
                        }
                        if (delta < 0 && islast) {
                            $("body").css({ overflow: "auto", height: "auto" })
                            $.fn.multiscroll.setMouseWheelScrolling()
                        }
                    });
                } else {
                    $("body").css({ overflow: "hidden", height: "100%" })
                    $('body').unbind('mousewheel')
                }
            }
        });
    }
}


//移动端banner
var mobBanswiper = new Swiper('.mob-banner .swiper-container', {
    direction: 'vertical',
    observer: true,
    observeParents: true,
    releaseOnEdges: true,
    touchReleaseOnEdges: true,
    pagination: {
        el: '.mob-banner .swiper-pagination',
        clickable: true,
    },
    on: {
        transitionStart: function (swiper) {
            $('html,body').animate({
                scrollTop:0
            },300)
        },
    },
});